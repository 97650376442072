/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import * as elecmap from "assets/img/postcodelec2017.geojson";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// react mapGL
import {render} from 'react-dom';
import MapGL, {Source, Layer} from 'react-map-gl';
// reactstrap components
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";
import {updatePercentiles} from './utils';
import ControlPanel from './control-panel';
import {dataLayer} from './map-style'
import {json as requestJson} from 'd3-request';
// class Map extends Component {
//   state = {
//     year: 2015,
//     data: null,
//     hoveredFeature: null,
//     viewport: {
//       latitude: 40,
//       longitude: -100,
//       zoom: 3,
//       bearing: 0,
//       pitch: 0
//     }
//   };

  

// };

class Map extends Component {
  state = {
    // year: 'Standard_E',
    // data: null,
    hoveredFeature: null,
    viewport: {
      latitude: 59.027633,
      longitude: -2.951757,
      zoom: 8,
      bearing: 0,
      pitch: 0
    }
  };

  // componentDidMount() {
  //   requestJson(
  //     'assets/img/postcodelec2017.geojson',
  //     (error, response) => {
  //       if (!error) {
  //         this._loadData(response);
  //       }
  //     }
  //   );
  // }

  // _loadData = elecmap => {
  //   this.setState({
  //     data: updatePercentiles(elecmap, f => f.properties.Standard_E[this.state.year])
  //   });
  // };

  // _updateSettings = (name, value) => {
  //   if (name === 'year') {
  //     this.setState({year: value});

  //     const {data} = this.state;
  //     if (data) {
  //       // trigger update
  //       this.setState({
  //         data: updatePercentiles(data, f => f.properties.Standard_E[value])
  //       });
  //     }
  //   }
  // };

  // // _onViewportChange = viewport => this.setState({viewport});
  // _loadData = data => {
  //   this.setState({
  //     data: updatePercentiles(data, f => f.properties.Standard_E[this.state.year])
  //   });
  // };

  _onViewportChange = viewport => this.setState({viewport});
  
  _onHover = event => {
    const {
      features,
      srcEvent: {offsetX, offsetY}
    } = event;
    const hoveredFeature = features && features.find(f => f.layer.id === 'elec');

    this.setState({hoveredFeature, x: offsetX, y: offsetY});
  };

  _renderTooltip() {
    const {hoveredFeature, x, y} = this.state;

    return (
      hoveredFeature && (
        <div className="tooltip1" style={{left: x, top: y}}>
          <div>Postcode: {hoveredFeature.properties.Postcode}</div>
          <div>Electricity (kWh): {hoveredFeature.properties.Standard_E}</div>
          {/* <div>Percentile: {(hoveredFeature.properties.percentile / 8) * 100}</div> */}
        </div>
      )
    );
  }

  render() {
    console.log(data)
    const {viewport, data} = this.state;

    return (

      <>
      <div className="content">
          <Row>
            <Col md="12">
              <Card className="card-plain">
                <CardHeader>Orkney Domestic Electricity Map</CardHeader>
                <CardBody>
                  <div
                    id="map"
                    className="map"
                    style={{ position: "relative", overflow: "hidden" }}>
                    <MapGL
                      {...viewport}
                      width="100%"
                      height="100%"
                      mapStyle="mapbox://styles/mapbox/light-v9"
                      onViewportChange={this._onViewportChange}
                      mapboxApiAccessToken={'pk.eyJ1Ijoia3Jpc3BpZXBhZ2UiLCJhIjoiY2p0YTJnZnIzMDZvNTRhbGlicHRkbGFiZyJ9._r8wZl2WIRFT9qGihCutMw'}
                      onHover={this._onHover}
                    >
                      {/* <Source type="geojson" data={data}>
                        <Layer id='elec'
                    type='fill'
                    source='my-data'
                    // // layout={{
                    //  'line-join': 'round',
                    //  'line-cap': 'round',
                    // }}
                    paint={{
                    'fill-color': [
                                  'interpolate',
                                  ['linear'],
                                  ['get', 'Standard_E'],
                                  0,
                                  '#FDE725',
                                  48,
                                  '#9FDA3A',
                                  88,
                                  '#4AC16D',
                                  135,
                                  '#1FA187',
                                  184,
                                  '#277F8E',
                                  240,
                                  '#365C8D',
                                  313,
                                  '#46337E',
                                  431,
                                  '#440154'
                                ],
                                'fill-opacity': 0.75
                      // 'line-width': 5,
                      // 'fill-color': 'rgba(3, 170, 238, 0.5)'
                    }}/>
                      </Source> */}
                      <Source type="geojson" data={elecmap}>
                        {/* <Layer {...dataLayer} /> */}
                        <Layer 
                        id='elec'
                        type='fill'
                        source='data'
                        // // layout={{
                        //  'line-join': 'round',
                        //  'line-cap': 'round',
                        // }}
                        paint={{
                        'fill-color': [
                                      'interpolate',
                                      ['linear'],
                                      ['get', 'Standard_E'],
                                      0,
                                      '#FDE725',
                                      48,
                                      '#9FDA3A',
                                      88,
                                      '#4AC16D',
                                      135,
                                      '#1FA187',
                                      184,
                                      '#277F8E',
                                      240,
                                      '#365C8D',
                                      313,
                                      '#46337E',
                                      431,
                                      '#440154'
                                    ],
                                    'fill-opacity': 0.75
                          // 'line-width': 5,
                          // 'fill-color': 'rgba(3, 170, 238, 0.5)'
                        }}
                        />
                      </Source>
                      {this._renderTooltip()}
                    </MapGL>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        {/* <ControlPanel
          containerComponent={this.props.containerComponent}
          settings={this.state}
          onChange={this._updateSettings}
        /> */}
      </div>
      </>
    );
  }
}

export function renderToDom(container) {
  render(<Map />, container);
}

// const MapWrapper = withScriptjs(
//   withGoogleMap(props => (
//     <GoogleMap
//       defaultZoom={13}
//       defaultCenter={{ lat: 40.748817, lng: -73.985428 }}
//       defaultOptions={{
//         scrollwheel: false, //we disable de scroll over the map, it is a really annoing when you scroll through page
//         styles: [
//           {
//             elementType: "geometry",
//             stylers: [
//               {
//                 color: "#1d2c4d"
//               }
//             ]
//           },
//           {
//             elementType: "labels.text.fill",
//             stylers: [
//               {
//                 color: "#8ec3b9"
//               }
//             ]
//           },
//           {
//             elementType: "labels.text.stroke",
//             stylers: [
//               {
//                 color: "#1a3646"
//               }
//             ]
//           },
//           {
//             featureType: "administrative.country",
//             elementType: "geometry.stroke",
//             stylers: [
//               {
//                 color: "#4b6878"
//               }
//             ]
//           },
//           {
//             featureType: "administrative.land_parcel",
//             elementType: "labels.text.fill",
//             stylers: [
//               {
//                 color: "#64779e"
//               }
//             ]
//           },
//           {
//             featureType: "administrative.province",
//             elementType: "geometry.stroke",
//             stylers: [
//               {
//                 color: "#4b6878"
//               }
//             ]
//           },
//           {
//             featureType: "landscape.man_made",
//             elementType: "geometry.stroke",
//             stylers: [
//               {
//                 color: "#334e87"
//               }
//             ]
//           },
//           {
//             featureType: "landscape.natural",
//             elementType: "geometry",
//             stylers: [
//               {
//                 color: "#023e58"
//               }
//             ]
//           },
//           {
//             featureType: "poi",
//             elementType: "geometry",
//             stylers: [
//               {
//                 color: "#283d6a"
//               }
//             ]
//           },
//           {
//             featureType: "poi",
//             elementType: "labels.text.fill",
//             stylers: [
//               {
//                 color: "#6f9ba5"
//               }
//             ]
//           },
//           {
//             featureType: "poi",
//             elementType: "labels.text.stroke",
//             stylers: [
//               {
//                 color: "#1d2c4d"
//               }
//             ]
//           },
//           {
//             featureType: "poi.park",
//             elementType: "geometry.fill",
//             stylers: [
//               {
//                 color: "#023e58"
//               }
//             ]
//           },
//           {
//             featureType: "poi.park",
//             elementType: "labels.text.fill",
//             stylers: [
//               {
//                 color: "#3C7680"
//               }
//             ]
//           },
//           {
//             featureType: "road",
//             elementType: "geometry",
//             stylers: [
//               {
//                 color: "#304a7d"
//               }
//             ]
//           },
//           {
//             featureType: "road",
//             elementType: "labels.text.fill",
//             stylers: [
//               {
//                 color: "#98a5be"
//               }
//             ]
//           },
//           {
//             featureType: "road",
//             elementType: "labels.text.stroke",
//             stylers: [
//               {
//                 color: "#1d2c4d"
//               }
//             ]
//           },
//           {
//             featureType: "road.highway",
//             elementType: "geometry",
//             stylers: [
//               {
//                 color: "#2c6675"
//               }
//             ]
//           },
//           {
//             featureType: "road.highway",
//             elementType: "geometry.fill",
//             stylers: [
//               {
//                 color: "#9d2a80"
//               }
//             ]
//           },
//           {
//             featureType: "road.highway",
//             elementType: "geometry.stroke",
//             stylers: [
//               {
//                 color: "#9d2a80"
//               }
//             ]
//           },
//           {
//             featureType: "road.highway",
//             elementType: "labels.text.fill",
//             stylers: [
//               {
//                 color: "#b0d5ce"
//               }
//             ]
//           },
//           {
//             featureType: "road.highway",
//             elementType: "labels.text.stroke",
//             stylers: [
//               {
//                 color: "#023e58"
//               }
//             ]
//           },
//           {
//             featureType: "transit",
//             elementType: "labels.text.fill",
//             stylers: [
//               {
//                 color: "#98a5be"
//               }
//             ]
//           },
//           {
//             featureType: "transit",
//             elementType: "labels.text.stroke",
//             stylers: [
//               {
//                 color: "#1d2c4d"
//               }
//             ]
//           },
//           {
//             featureType: "transit.line",
//             elementType: "geometry.fill",
//             stylers: [
//               {
//                 color: "#283d6a"
//               }
//             ]
//           },
//           {
//             featureType: "transit.station",
//             elementType: "geometry",
//             stylers: [
//               {
//                 color: "#3a4762"
//               }
//             ]
//           },
//           {
//             featureType: "water",
//             elementType: "geometry",
//             stylers: [
//               {
//                 color: "#0e1626"
//               }
//             ]
//           },
//           {
//             featureType: "water",
//             elementType: "labels.text.fill",
//             stylers: [
//               {
//                 color: "#4e6d70"
//               }
//             ]
//           }
//         ]
//       }}
//     >
//       <Marker position={{ lat: 40.748817, lng: -73.985428 }} />
//     </GoogleMap>
//   ))
// );

// class Map extends React.Component {
//   render() {
//     return (
//       <>
        // <div className="content">
        //   <Row>
        //     <Col md="12">
        //       <Card className="card-plain">
        //         <CardHeader>Google Maps</CardHeader>
        //         <CardBody>
        //           <div
        //             id="map"
        //             className="map"
        //             style={{ position: "relative", overflow: "hidden" }}
        //           >
//                     <MapWrapper
//                       googleMapURL="https://maps.googleapis.com/maps/api/js?key=YOUR_KEY_HERE"
//                       loadingElement={<div style={{ height: `100%` }} />}
//                       containerElement={<div style={{ height: `100%` }} />}
//                       mapElement={<div style={{ height: `100%` }} />}
//                     />
        //           </div>
        //         </CardBody>
        //       </Card>
        //     </Col>
        //   </Row>
        // </div>
//       </>
//     );
//   }
// }

export default Map;
