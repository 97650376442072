import * as React from 'react';
import {PureComponent} from 'react';

export default class ControlPanel extends PureComponent {
  render() {
    const {settings} = this.props;
    const pStyle = {
        color: 'black',
      };
    return (
      <div className="control-panel">
        <h3>Interactive GeoJSON</h3>
        <p style={pStyle}>
          Map showing average domestic electricy usage by postcode in year <b>{settings.year}</b>. Hover over a
          state to see details.
        </p>
        <p>
          Data source: <a href="www.census.gov">UK BEIS</a>
        </p>
        <hr />

        <div key={'year'} className="input">
          <label>Year</label>
          <input
            type="range"
            value={settings.year}
            min={2016}
            max={2018}
            step={1}
            onChange={evt => this.props.onChange('year', evt.target.value)}
          />
        </div>
      </div>
    );
  }
}